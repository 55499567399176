<template>
    <div class="hc44Box">
        <div class="hbBox1" v-if="getAdvData(16).children">
            <a :href="getAdvData(16).children[0].url" target="_blank" rel="noopener noreferrer">
                <img :src="getAdvData(16).children[0].image" class="hb1Bg" />
                <div class="hb1Box">
                    <p class="hb1">数字化转型</p>
                    <p class="hb3">威有软件致力于成为全球企业的数字化转型合作伙伴</p>
                </div>
            </a>
        </div>
        <div class="hbBox2">
            <div class="hb2Box">
                <div class="hbT">
                    <p class="ht1">数字化转型专家</p>
                    <p class="ht2 wow animate__animated animate__slideInUp">服务范围</p>
                </div>
                <div class="hbList wow animate__animated animate__zoomIn">
                    <ul>
                        <li class="hlLi">
                            <div class="hlBox">
                                <div class="hlb1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc16112.png" alt="" class="h1Img" />
                                </div>
                                <div class="hlb2">业务转型</div>
                            </div>
                        </li>
                        <li class="hlLi">
                            <div class="hlBox">
                                <div class="hlb1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc16113.png" alt="" class="h1Img" />
                                </div>
                                <div class="hlb2">技术咨询</div>
                            </div>
                        </li>
                        <li class="hlLi">
                            <div class="hlBox">
                                <div class="hlb1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc16114.png" alt="" class="h1Img" />
                                </div>
                                <div class="hlb2">运营数字化</div>
                            </div>
                        </li>
                        <li class="hlLi">
                            <div class="hlBox">
                                <div class="hlb1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc16115.png" alt="" class="h1Img" />
                                </div>
                                <div class="hlb2">企业数字化变革</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox3">
            <div class="hb3Box">
                <div class="hbT">
                    <p class="ht1">服务优势</p>
                    <p class="ht2">
                        威有软件持续以深厚的行业积累和丰富的技术经验，服务与各行业企业，致力于成就更美好的数字化新世界
                    </p>
                </div>
                <div class="hbList">
                    <ul>
                        <li class="hlLi" v-for="el in fw" :key="el.id">
                            <div class="hl1 wow animate__animated animate__slideInUp">
                                <img :src="el.image" class="hl1Icon" />
                            </div>
                            <p class="hl2 wow animate__animated animate__slideInUp">{{ el.title }}</p>
                            <p class="hl3 wow animate__animated animate__slideInUp">{{ el.describe }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox4">
            <div class="hb4Box">
                <div class="hbT">覆盖行业</div>
                <div class="hbList">
                    <ul>
                        <li class="hlLi wow animate__animated animate__fadeIn" v-for="item in hy" :key="item.id">
                            <img :src="item.image" class="hlBg" />
                            <div class="hlT">{{ item.title }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox5">
            <div class="hb5Box">
                <div class="hbT">转型合作伙伴</div>
                <div class="hbImgs">
                    <ul>
                        <li class="hiLi" v-for="item in hzhb" :key="item.id">
                            <img
                                @click="goDetails(item)"
                                :src="item.icon ? item.icon : 'https://iv.okvu.cn/vugw/img/jzsb.png'"
                                class="hilImg"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox6">
            <img src="http://iv.okvu.cn/vugw/img/hc16122.png" alt="" class="hb6Bg" />
            <div class="hb6Box">
                <div class="hbLeft">
                    <p class="hl1">合作咨询</p>
                    <p class="hl2">提交您的咨询内容，我们将尽快联系您。</p>
                    <div class="hl3 wow animate__animated animate__slideInUp">
                        <div class="hl31">
                            <img :src="getWebData().wechat_visitor" alt="" class="h31Code" />
                        </div>
                        <div class="hl32">扫描二维码 <br />立即咨询售前客服。</div>
                    </div>
                    <div class="hl4">
                        <ul>
                            <li class="h4Li wow animate__animated animate__slideInUp">
                                <div class="h4l1">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h4l1Icon" />
                                </div>
                                <p class="h4l2">邮件咨询: {{ getWebData().email }}</p>
                            </li>
                            <li class="h4Li wow animate__animated animate__slideInUp">
                                <div class="h4l1" style="width: 25px; margin-right: 5px">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h4l1Icon2" />
                                </div>
                                <p class="h4l2">售前开发咨询: {{ getWebData().phone }}</p>
                            </li>
                            <li class="h4Li wow animate__animated animate__slideInUp">
                                <div class="h4l1">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h4l1Icon3" />
                                </div>
                                <p class="h4l2">全国其他咨询: {{ getWebData().phone }}</p>
                            </li>
                            <li class="h4Li wow animate__animated animate__slideInUp">
                                <div class="h4l1">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h4l1Icon4" />
                                </div>
                                <p class="h4l2">QQ: {{ getWebData().qq }}</p>
                            </li>
                            <li class="h4Li wow animate__animated animate__slideInUp">
                                <div class="h4l1">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h4l1Icon5" />
                                </div>
                                <p class="h4l2">微信: {{ getWebData().phone }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hbRight wow animate__animated animate__slideInRight">
                    <div class="hrBox">
                        <form>
                            <div class="hrInp">
                                <p class="hiT">姓名</p>
                                <input type="text" v-model.trim="form.name" placeholder="请输入您的姓名" />
                            </div>
                            <div class="hrInp">
                                <p class="hiT">公司</p>
                                <input type="text" v-model.trim="form.company" placeholder="请输入您的公司名称" />
                            </div>
                            <div class="hrInp">
                                <p class="hiT">电话</p>
                                <input type="text" v-model.trim="form.phone" placeholder="请输入您的电话" />
                            </div>
                            <div class="hrInp">
                                <p class="hiT">邮箱</p>
                                <input type="text" v-model.trim="form.email" placeholder="请输入您的邮箱" />
                            </div>
                            <div class="hrInp2">
                                <p class="hiT">咨询内容</p>
                                <textarea
                                    cols="30"
                                    rows="10"
                                    v-model.trim="form.content"
                                    maxlength="300"
                                    placeholder="请输入您的咨询内容"
                                ></textarea>
                            </div>
                            <div class="hrBtn" @click="subForm">提交</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
export default {
    name: "HsContent44",
    inject: ["getWebData", "getAdvData"],
    data() {
        return {
            form: {
                name: "",
                phone: "",
                email: "",
                company: "",
                content: "",
            },
            // 服务优势
            fw: [],
            // 36个合作伙伴
            hzhb: [],
            // 覆盖行业
            hy: [],
        };
    },
    async mounted() {
        this.getHZHB();
        await this.getHY();
        await this.getFW();

        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    methods: {
        async subForm() {
            let { form } = this;
            if (iskong(form.name) || iskong(form.phone) || iskong(form.content)) {
                return this.$message.error("请勿为空！");
            }
            let { data } = await axios.post(
                "/api/mail/add",
                QueryString.stringify({
                    ...form,
                    channel: "数字化转型",
                })
            );

            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
                this.form = {
                    name: "",
                    phone: "",
                    email: "",
                    company: "",
                    content: "",
                };
            }

            function iskong(data = "") {
                // 为空
                if (!data.replaceAll(" ", "")) return true;
                return false;
            }
        },
        async getFW() {
            let { data } = await axios.get("/api/marketing/dtlist");
            this.fw = data;
        },
        async getHZHB() {
            let { data } = await axios.get("/api/partner/digitaltransformation");
            this.hzhb = data;
        },
        async getHY() {
            let { data } = await axios.get("/api/marketing/indshow");
            this.hy = data;
        },
        goDetails({ id, details, url, type }) {
            // 跳链接
            if (details == "" || details == "<p><br></p>" || details == null) {
                if (!url) return;
                if (url.indexOf("http") >= 0 || url.indexOf("https") >= 0) {
                    window.open(url);
                } else {
                    window.open("http://" + url);
                }
                return;
            }
            // 跳详情
            if (type == 1) {
                this.$router.push("/partner3Details?id=" + id);
            } else if (type == 2) {
                this.$router.push("/partner2Details?id=" + id);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.hc44Box {
    width: 100%;
    margin-top: 78px;
    overflow: hidden;
    .hbBox1 {
        width: 100%;
        height: 320px;
        position: relative;
        .hb1Bg {
            // width: 100%;
            height: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            // object-fit: cover;
            z-index: -1;
        }
        .hb1Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            color: white;
            .hb1 {
                width: 100%;
                height: 65px;
                line-height: 65px;
                font-size: 54px;
                font-weight: bold;
                margin-top: 100px;
            }
            .hb2 {
                width: 59px;
                height: 7px;
                // background-color: #fff;
                margin-top: 30px;
            }
            .hb3 {
                width: 100%;
                height: 23px;
                line-height: 23px;
                font-size: 20px;
                margin-top: 10px;
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 426px;
        .hb2Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .hbT {
                width: 100%;
                text-align: center;
                margin-top: 50px;
                .ht1 {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 32px;
                    font-weight: bold;
                }
                .ht2 {
                    width: 100%;
                    height: 37px;
                    line-height: 37px;
                    font-size: 18px;
                }
            }
            .hbList {
                width: 100%;
                height: 195px;
                margin-top: 30px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .hlLi {
                        width: 191px;
                        height: 195px;
                        border-radius: 10px;
                        border-radius: 10px;
                        box-shadow: 1px 1px 10px gainsboro;
                        transition: 0.5s;
                        .hlBox {
                            width: 75%;
                            height: 70%;
                            margin: 0 auto;
                            margin-top: 15%;
                            overflow: hidden;
                            .hlb1 {
                                width: 80px;
                                height: 80px;
                                margin: 0 auto;
                                margin-top: 10px;
                                .h1Img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .hlb2 {
                                width: 100%;
                                height: 24px;
                                line-height: 24px;
                                font-size: 20px;
                                text-align: center;
                                font-weight: bold;
                                margin-top: 10px;
                            }
                        }
                    }
                    .hlLi:hover {
                        transform: translateY(-20px);
                    }
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        height: 426px;
        .hb3Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .hbT {
                width: 100%;
                text-align: center;
                margin-top: 30px;
                .ht1 {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 32px;
                    font-weight: bold;
                }
                .ht2 {
                    width: 100%;
                    height: 37px;
                    line-height: 37px;
                    font-size: 18px;
                }
            }
            .hbList {
                width: 100%;
                height: 263px;
                margin-top: 30px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .hlLi {
                        width: 275px;
                        height: 100%;
                        text-align: center;
                        transition: 0.5s;
                        .hl1 {
                            width: 100%;
                            height: 124px;
                            .hl1Icon {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }

                        .hl2 {
                            width: 100%;
                            height: 29px;
                            font-size: 20px;
                            color: #4e5059;
                            font-weight: bold;
                            margin-top: 20px;
                        }
                        .hl3 {
                            width: 280px;
                            height: 84px;
                            margin: 0 auto;
                            font-size: 14px;
                            color: #5c5f6b;
                            margin-top: 20px;
                        }
                    }
                    .hlLi:hover {
                        transform: translateY(-20px);
                    }
                }
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 500px;
        .hb4Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            .hbT {
                width: 100%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 32px;
                color: #0f1523;
                font-weight: bold;
            }
            .hbList {
                width: 100%;
                height: 410px;
                margin-top: 30px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .hlLi {
                        width: 288px;
                        height: 194px;
                        border-radius: 10px;
                        overflow: hidden;
                        position: relative;
                        .hlBg {
                            width: 100%;
                            height: 100%;
                            transition: 0.5s;
                        }
                        .hlT {
                            width: 100%;
                            height: 45px;
                            color: white;
                            line-height: 45px;
                            text-align: center;
                            font-size: 22px;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            background-color: rgba(56, 132, 255, 0.5);
                        }
                    }
                    .hlLi:hover {
                        .hlBg {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
    .hbBox5 {
        width: 100%;
        height: 800px;
        margin-top: 30px;
        .hb5Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            .hbT {
                width: 100%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 32px;
                color: #0f1523;
                font-weight: bold;
            }
            .hbImgs {
                width: 100%;
                min-height: 668px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    // justify-content: space-around;
                    flex-wrap: wrap;
                    .hiLi {
                        width: 190px;
                        height: 100px;
                        border-radius: 20px;
                        overflow: hidden;
                        box-shadow: 1px 1px 10px #eee;
                        transition: 0.5s;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        background-color: white;
                        .hilImg {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            cursor: pointer;
                        }
                    }
                    .hiLi:hover {
                        transform: translateY(-20px);
                        box-shadow: 1px 1px 10px gainsboro;
                    }
                }
            }
        }
    }
    .hbBox6 {
        width: 100%;
        height: 569px;
        position: relative;
        text-align: left;
        .hb6Bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        .hb6Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            .hbLeft {
                width: 328px;
                float: left;
                color: white;
                overflow: hidden;
                margin-top: 45px;
                .hl1 {
                    width: 100%;
                    height: 36px;
                    font-size: 32px;
                    line-height: 36px;
                    margin-top: 40px;
                }
                .hl2 {
                    width: 100%;
                    height: 27px;
                    font-size: 12px;
                    margin-top: 10px;
                }
                .hl3 {
                    width: 100%;
                    height: 128px;
                    margin-top: 20px;
                    .hl31 {
                        width: 128px;
                        height: 128px;
                        float: left;
                        border-radius: 10px;
                        overflow: hidden;
                        .h31Code {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .hl32 {
                        width: 166px;
                        height: 56px;
                        float: left;
                        margin-left: 20px;
                        margin-top: 35px;
                    }
                }
                .hl4 {
                    width: 100%;
                    margin-top: 40px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        flex-direction: column;
                        .h4Li {
                            width: 100%;
                            height: 20px;
                            margin-top: 10px;
                            .h4l1 {
                                width: 30px;
                                height: 20px;
                                float: left;

                                position: relative;
                                overflow: hidden;
                                .h4l1Icon {
                                    width: 800px;
                                    position: absolute;
                                    bottom: -127px;
                                    right: -93px;
                                }
                                .h4l1Icon2 {
                                    width: 863px;
                                    position: absolute;
                                    bottom: -138px;
                                    right: -78px;
                                }
                                .h4l1Icon3 {
                                    width: 800px;
                                    position: absolute;
                                    bottom: -127px;
                                    right: -43px;
                                }
                                .h4l1Icon4 {
                                    width: 800px;
                                    position: absolute;
                                    bottom: -127px;
                                    right: 7px;
                                }
                                .h4l1Icon5 {
                                    width: 800px;
                                    position: absolute;
                                    bottom: -127px;
                                    right: -17px;
                                }
                            }
                            .h4l2 {
                                min-width: 221px;
                                height: 20px;
                                line-height: 20px;
                                margin-left: 5px;
                                float: left;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .hbRight {
                width: 820px;
                height: 479px;
                float: left;
                margin-left: 30px;
                margin-top: 50px;
                background-color: #fff;
                border-radius: 15px;
                .hrBox {
                    width: 95%;
                    height: 85%;
                    margin: 0 auto;
                    margin-top: 35px;
                    form {
                        width: 100%;
                        height: 100%;
                        .hrInp {
                            width: 384px;
                            height: 84px;
                            float: left;
                            overflow: hidden;
                            margin-top: 10px;
                            .hiT {
                                width: 100%;
                                height: 32px;
                                line-height: 32px;
                                margin-left: 20px;
                            }
                            input {
                                background-color: #f9faff;
                                width: 324px;
                                height: 36px;
                                margin: 0;
                                margin-left: 20px;
                                padding: 10px;
                                border: none;
                                border-radius: 5px;
                                box-sizing: border-box;
                                outline: none;
                            }
                        }
                        .hrInp2 {
                            width: 708px;
                            height: 146px;
                            float: left;
                            .hiT {
                                width: 100%;
                                height: 32px;
                                line-height: 32px;
                                margin-left: 20px;
                            }
                            textarea {
                                width: 708px;
                                height: 114px;
                                border: none;
                                border-radius: 10px;
                                outline: none;
                                padding-top: 10px;
                                padding-left: 10px;
                                box-sizing: border-box;
                                margin-left: 20px;
                                background-color: #f9faff;
                            }
                        }
                        .hrBtn {
                            width: 100px;
                            height: 34px;
                            line-height: 34px;
                            text-align: center;
                            color: white;
                            background-color: #7781f1;
                            border-radius: 20px;
                            float: right;
                            margin-top: 30px;
                            margin-right: 45px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>
